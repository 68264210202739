import React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

export const GroupPrivacyLevel: React.FC<
  InjectedTranslateProps & {
    privacyLevel: ApiTypes.v1.GroupPrivacyLevel;
  }
> = ({ t, privacyLevel }) => {
  return t(`groups-web.group-list.privacy.${privacyLevel.toLowerCase()}`);
};
