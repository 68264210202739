import React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';
import { withTPAConfig } from '@wix/social-groups-common/dist/src/components/withTPAConfig';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  WithAppSettingsProps,
  withAppSettings,
} from '../../contexts/withAppSettings';
import { LayoutType } from '../../settings/settingsConstants';

import { GroupCardProps, GroupListCardProps } from './types';
import { classes, st } from './layout/GroupCard.st.css';
import { GroupGridCard } from './layout/GroupGridCard';
import { GroupListCard } from './layout/GroupListCard';
import { isUndefinedOrTrue } from '../utils/utils';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';

type Props = WithAppSettingsProps &
  InjectedTranslateProps &
  Omit<GroupCardProps, 'imageRatio'>;

export const GroupCardComponent: React.FC<Props> = ({
  appSettings,
  ...props
}) => {
  const { mobile } = props;
  let Component: React.ComponentType<GroupCardProps | GroupListCardProps>;

  const imageRatio = appSettings[getSettingsKeyFor('imageRatio', mobile)];
  const layout = appSettings[getSettingsKeyFor('groupListLayout', mobile)];

  const specificProps = {
    [LayoutType.list]: {
      hasSpacing: !!appSettings[getSettingsKeyFor('listCardSpacing', mobile)],
    },
    [LayoutType.grid]: {},
  };

  switch (layout) {
    case LayoutType.grid:
      Component = GroupGridCard;
      Component.displayName = 'GroupGridCard';
      break;
    case LayoutType.list:
    default:
      Component = GroupListCard;
      Component.displayName = 'GroupListCard';
  }

  return (
    <Component
      {...props}
      imageRatio={imageRatio}
      showGroupLogo={isUndefinedOrTrue(
        appSettings[getSettingsKeyFor('showImage', mobile)],
      )}
      layout={layout}
      {...specificProps[layout]}
      className={st(classes.root, { mobile })}
    />
  );
};

const enhance = compose(translate(), withAppSettings, withTPAConfig);
export const GroupCard = enhance(GroupCardComponent) as React.ComponentType<
  Omit<GroupCardProps, 'imageRatio'>
>;
GroupCard.displayName = 'GroupCard';
