import React from 'react';
import { Button } from '../Button/Button';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Modal } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { canResolvePendingGroups, memberWrapper } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { DeclineGroupRequestModal } from '../modals/DeclineGroupRequestModal';
import {
  withGroupsActions,
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';
import { classes } from './PendingGroupActions.st.css';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';
import { DeleteGroupRequestModal } from '../modals/DeleteGroupRequestModal';

const { APPROVED, REJECTED } = ApiTypes.v1.GroupApprovalStatus;

export interface PendingGroupActionsProps {
  group: ApiTypes.v1.GroupResponse;
}

export interface PendingGroupActionsState {
  isModalOpen: boolean;
}

export class PendingGroupActionsComponent extends React.Component<
  InjectedTranslateProps &
    PendingGroupActionsProps &
    WithGroupsActionsProps &
    WithCurrentUserProps,
  PendingGroupActionsState
> {
  readonly state: PendingGroupActionsState = {
    isModalOpen: false,
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  onApproveClick = () => {
    const {
      actions: { setPendingGroupApprovalStatus },
      group,
    } = this.props;

    setPendingGroupApprovalStatus(group, APPROVED);
  };

  viewGroup = () => {
    this.props.actions.goToGroup(this.props.group.slug);
  };

  handleConfirmGroupRequestDeletion = () => {
    this.props.actions.withdrawGroupCreationRequest(this.props.group);
  };

  handleConfirmGroupRejection = (rejectionReason) => {
    const {
      actions: { setPendingGroupApprovalStatus },
      group,
    } = this.props;

    setPendingGroupApprovalStatus(group, REJECTED, rejectionReason);
  };

  renderAdminActions() {
    const { group, t } = this.props;
    const { isModalOpen } = this.state;

    const { name } = memberWrapper(group.createdBy);
    const creatorName = name.nick || t('groups-web.member.anonymous');
    return (
      <>
        <Button
          data-hook="reject-button"
          priority={PRIORITY.secondary}
          onClick={this.openModal}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.group-list.pending-groups.button.decline')}
        </Button>
        <Button
          data-hook="approve-button"
          priority={PRIORITY.primary}
          onClick={this.onApproveClick}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.group-list.pending-groups.button.approve')}
        </Button>
        <Modal isOpen={isModalOpen} onRequestClose={this.closeModal}>
          <DeclineGroupRequestModal
            data-hook={'declineGroupRequestModal'}
            onConfirmGroupRejection={this.handleConfirmGroupRejection}
            onRequestClose={this.closeModal}
            creatorName={creatorName}
          />
        </Modal>
      </>
    );
  }

  renderCreatorButtons() {
    const { t } = this.props;
    const { isModalOpen } = this.state;

    return (
      <>
        <Button
          data-hook="view-button"
          priority={PRIORITY.secondary}
          onClick={this.viewGroup}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.btn.view-group')}
        </Button>
        <Button
          data-hook="withdraw-button"
          priority={PRIORITY.primary}
          onClick={this.openModal}
          fullWidth={true}
          className={classes.actionButton}
        >
          {t('groups-web.group-list.pending-groups.button.delete')}
        </Button>
        <Modal isOpen={isModalOpen} onRequestClose={this.closeModal}>
          <DeleteGroupRequestModal
            data-hook={'deleteGroupRequestModal'}
            onConfirmGroupDeletion={this.handleConfirmGroupRequestDeletion}
            onRequestClose={this.closeModal}
          />
        </Modal>
      </>
    );
  }

  render() {
    const { currentUser } = this.props;

    return (
      <div className={classes.root}>
        {canResolvePendingGroups(currentUser)
          ? this.renderAdminActions()
          : this.renderCreatorButtons()}
      </div>
    );
  }
}

const enhance = compose(translate(), withGroupsActions, withCurrentUser);

export const PendingGroupActions = enhance(PendingGroupActionsComponent);
