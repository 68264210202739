import React from 'react';
import { GroupsConsumer } from './GroupsContext';
import { WithGroupsProps } from './withGroupsProps';

export const withGroups = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) =>
  class WithGroups extends React.Component<P & WithGroupsProps> {
    render() {
      return (
        <GroupsConsumer>
          {(data) => <WrappedComponent {...data} {...this.props} />}
        </GroupsConsumer>
      );
    }
  };

export default withGroups;
