import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { ExpandableContainer } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { MemberInvites } from '@wix/social-groups-common/dist/src/context';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { WithGroupsActionsProps } from '../../contexts/withGroupsActions';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';
import { GroupListCard } from '../GroupCard/layout/GroupListCard';
import { AppRootActions } from '../App/types';

import { PendingGroupMeta } from './PendingGroupMeta';
import { PendingGroupActions } from './PendingGroupActions';
import { PendingGroupsMobile } from './PendingGroupsMobile';

import { classes } from './PendingGroupList.st.css';
import { classes as cardClasses } from '../GroupCard/layout/GroupCard.st.css';
import classnames from 'classnames';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { getGroupUrl } from '../utils/utils';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';

const { PENDING } = ApiTypes.v1.GroupApprovalStatus;

export interface PendingGroupListProps {
  actions: AppRootActions;
  groups: ApiTypes.v1.GroupResponse[];
  couldUserResolvePendingGroups: boolean;
  mobile: boolean;
  groupSectionUrl: string;
}

function pendingGroupsFilter(group: ApiTypes.v1.GroupResponse) {
  return group.approvalStatus === PENDING;
}

type Props = PendingGroupListProps &
  WithGroupsProps &
  WithGroupsActionsProps &
  MemberInvites &
  InjectedTranslateProps &
  WithAppSettingsProps;

export function PendingGroupListComponent(props: Props) {
  const {
    appSettings,
    couldUserResolvePendingGroups,
    groups,
    mobile,
    t,
    groupSectionUrl,
  } = props;

  const imageRatio = appSettings[getSettingsKeyFor('imageRatio', false)];
  const pendingGroups = groups.filter(pendingGroupsFilter);

  if (mobile) {
    return <PendingGroupsMobile total={pendingGroups.length} />;
  }

  const title = couldUserResolvePendingGroups
    ? 'groups-web.group-list.pending-groups.title'
    : 'groups-web.group-list.pending-groups.creator-title';

  return (
    <ExpandableContainer
      title={
        <Text typography={TYPOGRAPHY.runningText} className={classes.title}>
          {t(title)}
          <span className={classes.badge}>{pendingGroups.length}</span>
        </Text>
      }
      className={classes.root}
    >
      {pendingGroups.map((group) => (
        <GroupListCard
          key={group.groupId}
          t={t}
          hasSpacing={false}
          mobile={false}
          group={group}
          imageRatio={imageRatio}
          cardMetaComponent={PendingGroupMeta}
          cardActionsComponent={PendingGroupActions}
          groupUrl={getGroupUrl(groupSectionUrl, group)}
          className={classnames(classes.groupCard, cardClasses.root)}
        />
      ))}
    </ExpandableContainer>
  );
}

const enhance = compose(translate(), withAppSettings);

export const PendingGroupList = enhance(
  PendingGroupListComponent,
) as React.ComponentType<PendingGroupListProps>;
