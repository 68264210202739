import React from 'react';
import { Button as TPAButton, ButtonProps } from 'wix-ui-tpa/Button';

import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';

import { st, classes } from './Button.st.css';
import { withTPAConfig } from '@wix/social-groups-common/dist/src/components/withTPAConfig';
import { TPAComponentsConfig } from 'wix-ui-tpa/dist/es/src';

const ButtonComponent: React.FC<
  WithAppSettingsProps & ButtonProps & TPAComponentsConfig
> = ({ appSettings, ...props }) => (
  <TPAButton
    {...props}
    className={st(
      classes.root,
      {
        type: appSettings.buttonType,
        mobile: props.mobile,
      },
      props.className,
    )}
    upgrade
  />
);

export const Button = withTPAConfig(
  withAppSettings(ButtonComponent),
) as React.ComponentType<ButtonProps>;
Button.displayName = 'Button';
