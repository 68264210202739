export enum Alignment {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum LayoutType {
  grid = 'grid',
  list = 'list',
  listWidget = 'listWidget',
}

export enum ButtonType {
  rectangle = 'rectangle',
  rounded = 'rounded',
}

export enum ImageRatio {
  rectangle = 'rectangle',
  square = 'square',
}

export const MAX_LAYOUT_SPACING = 100;
export const DEFAULT_GRID_CARD_SPACING = 12;
export const MAX_BUTTON_BORDER_RADIUS = 100;
