import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { Modal } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { TextArea } from 'wix-ui-tpa/TextArea';

import { DATA_HOOKS } from './constants';
import { st, classes } from './DeclineGroupRequestModal.st.css';

export interface DeclineGroupRequestModalProps {
  creatorName: string;

  onConfirmGroupRejection(rejectionReason): void;

  onRequestClose(e): void;
}

interface DeclineGroupRequestModalState {
  rejectionReason: string;
}

export class DeclineGroupRequestModalComponent extends React.Component<
  InjectedTranslateProps & DeclineGroupRequestModalProps,
  DeclineGroupRequestModalState
> {
  readonly state: DeclineGroupRequestModalState = {
    rejectionReason: '',
  };

  handleRejectionReasonChange = (e) => {
    this.setState({
      rejectionReason: e.target.value,
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { rejectionReason } = this.state;
    this.props.onConfirmGroupRejection(rejectionReason);
  };

  render() {
    const { t, creatorName, onRequestClose } = this.props;

    return (
      <form
        data-hook={DATA_HOOKS.FORM}
        onSubmit={this.handleOnSubmit}
        className={st(classes.root)}
      >
        <Modal.Header
          title={t('groups-web.modal.decline-group.title')}
          subtitle={t('groups-web.modal.decline-group.subtitle', {
            memberName: creatorName,
          })}
        />
        <TextArea
          data-hook={DATA_HOOKS.REJECTION_REASON_INPUT}
          placeholder={t(
            'groups-web.modal.decline-group.rejectionReason.placeholder',
          )}
          onChange={this.handleRejectionReasonChange}
          value={this.state.rejectionReason}
          ariaLabel={t(
            'groups-web.modal.decline-group.rejectionReason.placeholder',
          )}
          className={classes.rejectionReasonInput}
        />
        <Modal.Buttons>
          <Button priority={PRIORITY.secondary} onClick={onRequestClose}>
            {t('groups-web.modal.decline-group.action.cancel')}
          </Button>
          <Button type="submit" priority={PRIORITY.primary}>
            {t('groups-web.modal.decline-group.action.decline')}
          </Button>
        </Modal.Buttons>
      </form>
    );
  }
}

const enhance = compose(translate());

export const DeclineGroupRequestModal = enhance(
  DeclineGroupRequestModalComponent,
) as React.ComponentType<DeclineGroupRequestModalProps>;
