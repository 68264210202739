import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { Modal } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { classes } from './DeleteGroupRequestModal.st.css';

export interface DeclineGroupRequestModalProps {
  onConfirmGroupDeletion(e): void;
  onRequestClose(e): void;
}

export function DeleteGroupRequestModalComponent(
  props: InjectedTranslateProps & DeclineGroupRequestModalProps,
) {
  const { onRequestClose, onConfirmGroupDeletion, t } = props;

  return (
    <>
      <Modal.Header title={t('groups-web.modal.delete-group-request.title')} />
      <Text typography={TYPOGRAPHY.runningText} className={classes.subtitle}>
        {t('groups-web.modal.delete-group-request.subtitle')}
      </Text>
      <Modal.Buttons>
        <Button priority={PRIORITY.secondary} onClick={onRequestClose}>
          {t('groups-web.modal.decline-group.action.cancel')}
        </Button>
        <Button
          type="submit"
          priority={PRIORITY.primary}
          onClick={onConfirmGroupDeletion}
        >
          {t('groups-web.modal.decline-group.action.delete')}
        </Button>
      </Modal.Buttons>
    </>
  );
}

const enhance = compose(translate());

export const DeleteGroupRequestModal = enhance(
  DeleteGroupRequestModalComponent,
) as React.ComponentType<DeclineGroupRequestModalProps>;
