import React from 'react';
import { AppSettingsContextValue } from './AppSettingsContextValue';

export const AppSettingsContext = React.createContext<AppSettingsContextValue>({
  /**
   * Design settings
   */
  buttonType: null,

  /**
   * Display settings
   */
  showHeaderTitle: null,
  showMemberCount: null,
  showSearchSorting: null,
  showAdminBadge: null,
  showImage: null,
  mobileShowHeaderTitle: null,
  mobileShowMemberCount: null,
  mobileShowSearchSorting: null,
  mobileShowAdminBadge: null,
  mobileShowImage: null,

  /**
   * Layout settings
   */
  gridCardSpacing: null,
  listCardSpacing: null,
  groupListLayout: null,
  headerAlignment: null,
  imageRatio: null,
  mobileImageRatio: null,
  mobileGroupListLayout: null,
  mobileGridCardSpacing: null,
  mobileListCardSpacing: null,

  /**
   * Text settings
   */
  groupListTitle: null,
  groupMembersLabel: null,

  /**
   * Settings settings
   */
  sortBy: null,
  styleParams: null,
});
