import React from 'react';
import { GroupsPageData } from '../components/App/types';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';

const context = React.createContext<Partial<GroupsPageData>>({
  updateProgress: UpdateProgress.UPDATING,
});
context.displayName = 'GroupsContext';

export const GroupsProvider = context.Provider;
export const GroupsConsumer = context.Consumer;
export default context;
