import React from 'react';

import { App } from './App';
import { GroupPageWithStyles } from './types';
import { AppToastsProvider } from '@wix/social-groups-common/dist/src/components';
import { ErrorLoggerProvider } from '@wix/social-groups-common/dist/src/context/error-logger';
import {
  Environment,
  createBoundaryConfig,
} from '@wix/social-groups-common/dist/src/loggers/sentryConfig';

import { MemberInvitesProvider } from '@wix/social-groups-common/dist/src/context';
import { GroupsAppProvider } from '../../contexts/GroupsAppProvider';

class ApplicationRoot extends React.Component<GroupPageWithStyles> {
  static displayName = 'ApplicationRoot';
  componentDidMount() {
    this.reportAppLoaded();
  }

  render() {
    const {
      toasts,
      appSettings,
      memberInvitesLink,
      loadMemberInvitesLink,
    } = this.props;

    if (!appSettings) {
      return null;
    }
    return (
      <ErrorLoggerProvider
        LazySentry={this.props.host.LazySentry}
        sentryConfig={createBoundaryConfig(Environment.VIEWER)}
      >
        <GroupsAppProvider {...this.props}>
          <AppToastsProvider value={toasts}>
            <MemberInvitesProvider
              value={{ memberInvitesLink, loadMemberInvitesLink }}
            >
              <App />
            </MemberInvitesProvider>
          </AppToastsProvider>
        </GroupsAppProvider>
      </ErrorLoggerProvider>
    );
  }

  private reportAppLoaded() {
    if (!this.props.reportAppLoaded) {
      return;
    }
    try {
      this.props.host.registerToComponentDidLayout(this.props.reportAppLoaded);
    } catch (e) {
      console.log('Error in ApplicationRoot.reportAppLoaded');
    }
  }
}

export default ApplicationRoot;
