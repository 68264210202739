import React from 'react';
import { GroupsActionsConsumer } from './GroupsActionsContext';
import { AppRootActions } from '../components/App/types';

export interface WithGroupsActionsProps {
  actions: AppRootActions;
}

export const withGroupsActions = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return class WithGroupsActions extends React.Component<
    P & WithGroupsActionsProps
  > {
    render() {
      return (
        <GroupsActionsConsumer>
          {(actions) => <WrappedComponent actions={actions} {...this.props} />}
        </GroupsActionsConsumer>
      );
    }
  };
};

export default withGroupsActions;
