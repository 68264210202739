import React from 'react';
import { CurrentUserConsumer } from './CurrentUserContext';
import { GroupsPageProps } from '../components/App/types';

export interface WithCurrentUserProps {
  currentUser: GroupsPageProps['currentUser']; // TODO: WHY???
}

export const withCurrentUser = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) =>
  class WithCurrentUser extends React.Component<P & WithCurrentUserProps> {
    render() {
      return (
        <CurrentUserConsumer>
          {(currentUser) => (
            <WrappedComponent currentUser={currentUser} {...this.props} />
          )}
        </CurrentUserConsumer>
      );
    }
  };
