import { InjectedTranslateProps, translate } from 'react-i18next';

import { MembersLabelWithCountDict } from './MembersLabel';
import React from 'react';
import { compose } from '@wix/social-groups-common/dist/src/compose';

interface MembersLabelWithCountProps {
  groupMemberLabel?: string;
  count: number;
}

const MembersLabelWithCountComponent = (
  props: MembersLabelWithCountProps & InjectedTranslateProps,
) => {
  const { t, groupMemberLabel, count } = props;
  const translationKey = groupMemberLabel
    ? MembersLabelWithCountDict[groupMemberLabel]
    : MembersLabelWithCountDict.Members;

  const label = translationKey
    ? t(translationKey, {
        count,
      })
    : `${count} ${groupMemberLabel}`;
  return <span data-hook="members-count">{label}</span>;
};

const enhance = compose(translate());
export const MembersLabelWithCount = enhance(
  MembersLabelWithCountComponent,
) as React.ComponentType<MembersLabelWithCountProps>;
