import { withStyles } from '@wix/native-components-infra/dist/src/HOC/withStyles/withStyles';
import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {
  Environment,
  createBoundaryConfig,
} from '@wix/social-groups-common/dist/src/loggers/sentryConfig';

import AppRoot from '../components/App/AppRoot';

export default {
  component: withSentryErrorBoundary(
    withStyles(AppRoot, {
      cssPath: ['viewerApp.stylable.bundle.css'],
    }),
    createBoundaryConfig(Environment.VIEWER),
  ),
};
